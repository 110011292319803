import { HttpStatusCode } from '@solidjs/start';
import dayjs from '@troon/dayjs';
import { ArticleTile, Button, Container, Heading, HorizontalRule, Link, Page, Pagination, Section } from '@troon/ui';
import { createAsync, useParams } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { Link as HeadLink, Title } from '@solidjs/meta';
import { IconArrowLeftMd } from '@troon/icons/arrow-left-md';
import { createContentfulListRequest } from '../../content/[model]/_client';

const perPage = 12;

export default function PressReleasePaginated() {
	const params = useParams<{ page?: string }>();
	const releases = createAsync(
		async () => getPressReleases({ skip: (parseInt(params.page ?? '1', 10) - 1) * perPage, limit: perPage }),
		{
			deferStream: true,
		},
	);

	return (
		<Container>
			<Page>
				<Title>
					Press Releases<Show when={params.page}>{(page) => ` | Page ${page()}`}</Show> | Troon
				</Title>
				<Show when={params.page === '1'}>
					<HeadLink rel="canonical" href="/press-releases" />
				</Show>
				<Section>
					<Heading as="h1">Press Releases</Heading>
					<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
						<Suspense>
							<For
								each={releases()?.items}
								fallback={
									<div class="flex flex-col gap-8">
										<HttpStatusCode code={404} />
										<p>No press releases found.</p>
										<Button class="w-fit" as={Link} href="/press-releases">
											<IconArrowLeftMd />
											Back to Press Releases
										</Button>
									</div>
								}
							>
								{(release, i) => {
									const image = release.fields.heroImage?.fields;
									return (
										<ArticleTile
											hero={
												image?.file
													? {
															src: image?.file?.url,
															alt: image?.description ?? image?.title ?? '',
															preload: i() < 3,
															loading: i() < 6 ? 'eager' : 'lazy',
														}
													: undefined
											}
											url={`/press-releases/${release.fields.slug}`}
											title={release.fields.title}
											date={dayjs(release.fields.publishDate, 'America/Phoenix').toDate()}
										/>
									);
								}}
							</For>
						</Suspense>
					</div>
				</Section>

				<Suspense>
					<Show when={releases()?.items?.length}>
						<Show when={releases()}>
							{(data) => (
								<>
									<HorizontalRule />
									<Pagination
										currentPage={parseInt(params.page ?? '1', 10)}
										lastPage={Math.ceil(data()!.total / perPage)}
										url={(page) => `/press-releases/page/${page}`}
										nextTitle="Older"
										prevTitle="Newer"
									/>
								</>
							)}
						</Show>
					</Show>
				</Suspense>
			</Page>
		</Container>
	);
}

const getPressReleases = createContentfulListRequest('press-release');
